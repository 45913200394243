<template>
  <div class="Iframe">
    <el-button type="primary" class="fh" @click="fh">返回</el-button>
    <el-button type="primary" style="position: absolute;bottom: 50px;left: 0;margin: 0;" v-if="this.$route.query.type == 20 || this.$route.query.type == 21" @click="$router.push({path: '/'})">返回首页</el-button>
    <el-button type="primary" class="fh dy" @click="dy" v-if="type==7">打印</el-button>
    <iframe class="znr" ref="prints" :src="src"></iframe>
  </div>
</template>

<script>
import isShow from "@/store/moddules/isShow";
import pf from '@/publicFn/baseFn'



export default {
  name: "Iframe",
  data() {
    return {
      src: '',
      type: 0
    }
  },
  watch: {
    '$store.state.isShow.iframeType': function (n, o) {
      let type = n.type
      switch (Number(type)) {
        case 0:  //历史报告
          let CustomerId = this.$store.state.users.CsUser.CustomerId
          this.src = `http://c.visumall.com/report/customer/inquirydetailReport.aspx?CustomerId=${CustomerId}&ChooseId=${n.value}`
          break
        case 1: //我的订单
          this.src = `https://weixin.visumall.com/customerorderCCP/CustomerOrderPay?COrderId=${n.value}`
          break
        case 2: //角膜报告
          this.src =  pf.getUrl('RGPReport') + `COrderId=${n.value}`
          break
      }
    },
    '$route.query': function (n, o) {
       this.init()
    }
  },
  created() {
    this.init()
    window.addEventListener( "message", this.tz)
  },

  methods: {
    dy() {
      let times = ((new Date()).valueOf() - this.$store.state.Time.timeObj.eyesHealthReport.ENTER)/1000 + Number(this.$store.state.Time.timeObj.eyesHealthReport.EXIT)
      this._api.publicApi.addTime('eyesHealthReport', times)
      this.$print(this.$refs.prints, {
        maxWidth: 1920,
        iframe: true
      })
    },

    tz(e) {
      if (e && e.data) {
        if (e.data.content == 'examinationEyesHome') {
          this.$router.push({path: '/home/examinationEyesHome'})
        }
      }
    },

    init() {
      this.$store.commit('isShow/upBottom', false)
      let type = this.$route.query.type
      let csid = this.$store.state.physicianVisits.xzConsulting,
          mdid = this.$store.state.bases.shop.ShopId,
          custid = this.$store.state.users.CsUser.CustomerId,
          exids = this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:'',
          companyid = this.$store.state.users.user.Company.CompanyId,
          uids = this.$store.state.users.user.UserId
      switch (Number(type)) {
        case 0: //历史报告
          let csId = this.$route.query.csId,
              CustomerId = this.$route.query.cusid || this.$store.state.users.CsUser.CustomerId
          this.src = `http://c.visumall.com/report/customer/inquirydetailReport.aspx?CustomerId=${CustomerId}&ChooseId=${csId}`
          break
        case 1:  //我的订单
          let oid = this.$route.query.oid
          let exid = this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:''
          let urls = pf.getUrl("CustomerOrderPay")
          this.src = urls + `COrderId=${oid}&ExpertId=${exid}` + "&UserId=" + uids
          break
        case 2: //角膜报告
          this.src = pf.getUrl('RGPReport') + 'ChooseID=' + csid + '&CustomerID=' + custid
            // console.log(this.src)
          break
        case 3: //温医结算ERP
          break
        case 4:
          break
        case 5:  //收费检查的跳转
          let arr = this.$store.state.users.user.Company.ListCompanyConfig
          let items  = '', url = ''
          let yg =  this.$store.state.users.employees,
              csID = this.$store.state.users.CsUser.CustomerId,
              chosID = this.$store.state.physicianVisits.xzConsulting,
              uid = this.$store.state.users.user.UserId,
              shopID = this.$store.state.bases.shop.ShopId
          if (arr && arr.length > 0) {
            items = arr.filter(item => item.BCKey == 'ERP_ExamSell')
          }
          if (yg.ListERPCode) {
            return this.$alert('暂无权限！', '提示')
                .then(res => {
                  this.$router.back(-1)
                })
          }
          if (items && items.length > 0) {
            url = items[0].BCValue.split('?')[0]
            url = url + `?user_id=${yg.ListERPCode[0].ObjectCode}&login_type=visumall&CustomerId=${csID}&ChooseId=${chosID}&COAddUserId=${uid}&COExpertId=${yg.ExpertId}&COShopId=${shopID}`
            this.src = url
          }
          break
        case 6: //我的顾客过来的详情报告
          this.src = `http://c.visumall.com/report/customer/inquirydetailReport.aspx?CustomerId=${this.$route.query.csid}&ChooseId=${this.$route.query.chid}`
          break
        case 7: //电子病历过来
          this.type = 7
          if (!this.$store.state.users.employees) {
            return this.$alert('店员未登陆', '提示')
                .then(res => {
                  this.$router.push({path: '/employeesLanding'})
                })
          }
          if (this.$store.state.physicianVisits.xzConsulting) {
            this.src = pf.getUrl('ChooseReport_MR') + `CustomerId=${this.$store.state.users.CsUser.CustomerId}&ChooseId=${this.$store.state.physicianVisits.xzConsulting}&ExpertId=${this.$store.state.users.employees.ExpertId}`
          }else {
            this.$alert('客户登陆或选择咨询', '提示')
                .then(res => {
                  this.$router.back(-1)
                })
          }
          break
        case 8: //框架定配中心-框架眼镜报告
          if (this.$store.state.physicianVisits.xzConsulting) {
            this.src = pf.getUrl('CentralizedReport') + `CustomerId=${this.$store.state.users.CsUser.CustomerId}&ChooseId=${this.$store.state.physicianVisits.xzConsulting}`
          }else {
            this.$alert('客户登陆或选择咨询', '提示')
                .then(res => {
                  this.$router.back(-1)
                })
          }
          break
        case 9: //框架训练-训练记录
          if (!this.$store.state.physicianVisits.xzConsulting) {
            return this.$alert('请选择咨询或新建咨询！', '提示')
                .then(res => {
                  this.$router.push({path: '/home/acceptsHome'})
                })
          }
          this.src = pf.getUrl('eyesTrain_records') + this.$store.state.physicianVisits.xzConsulting
          break
        case 10: //在线训练方案结束
          this.src = 'https://www.topeye.cn/bbs/intro/'
          break
        case 11: //训练操作指导书
          this.src = pf.getUrl('eyesTrainInstructor')
          break
        case 12: //现在训练处方
          let srcs =  pf.getUrl('eyesTrain_records') + `?chooseid=${chosID}&CustomerId=${custid}&ExpertId=${exids}`
          this.src = srcs
            console.log(this.src)
          break
        case 13: //现在训练处方
          this.src = this.$route.query.src
          break
        case 14: //产品详情
          let isz = true
          let arrs = this.$store.state.users.user.Company.ListCompanyConfig
          for (let i = 0; i < arrs.length; i++) {
            if (arrs[i].BCKey == 'ShowSelfProductInZTHT' && arrs[i].BCValue == 1) {
              isz = false
              break
            }
          }
          let cid = isz?this.$store.state.physicianVisits.xzConsulting:''
          let srcp = pf.getUrl("Product_WebDetail")
            // console.log(pf.getUrl("Product_WebDetail"))
          srcp = srcp.replace("{ChooseId}", cid)
          srcp = srcp.replace("{ProductId}", this.$route.query.id)
          srcp =   srcp.replace("{ShopId}", mdid)
            this.src = srcp
          //this.src = `http://www.visumall.com/#/Product?CHPProductId=${this.$route.query.id}&CHPChooseId=${cid}`
          break
        case 15:
          this.src = this.$route.query.src
          break
        case 18://积分充值
          this.src = pf.getUrl('CustomerIntegralOrderConfirm') + `CompanyId=${companyid}&CustomerId=${custid}&ExpertId=${exids}&UserId=${uids}&ShopId=${mdid}`
          break
        case 19:
          this.src = pf.getUrl('CustomerOrderPrint') + "COrderId=" + this.$route.query.oid
          break
        case 20:
          let ty = 1
          if (this.$route.query.sstype) ty = this.$route.query.sstype
          this.src = 'http://soperation.visumall.com/#/home?'+ 'chooseId=' + csid + '&expertId=' + exids+ '&type='+ ty + "&customerId=" + custid
            // console.log(this.src)
          break
        case 21:
          this.src = 'http://soperation.visumall.com/#/home?'+ 'chooseId=' + csid + '&expertId=' + exids + '&type='+ 2 + "&customerId=" + custid
          // console.log(this.src)
          break
        case 22:
          this.src = 'https://weixin.visumall.com/customerorder/TopeyeSolution?ShopId=' + mdid + "&CustomerID=" + csid
          // console.log(this.src)ShopId=55C4D225-230C-4C91-8981-376145E44101&CustomerID=4b4400e2-2eda-4cb3-bebb-43d32d1bcf54
          break
      }
    },

    fh() {
      this.$router.back(-1)
    },

    cshTime(keys) {
      this.$store.commit('Time/upTimeObj', {
        keys: keys,
        val: {
          ENTER: (new Date()).valueOf(),
          EXIT: this.$store.state.Time.timeObj[keys].EXIT
        }
      })
    },
    jsTime(keys,types=0) {
      if (types) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: 0,
            EXIT: 0
          }
        })
        return
      }
      if (this.$store.state.Time.timeObj[keys].ENTER !== 0) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: (new Date()).valueOf(),
            EXIT: ((new Date()).valueOf() - this.$store.state.Time.timeObj[keys].ENTER)/1000 + Number(this.$store.state.Time.timeObj[keys].EXIT)
          }
        })
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('message', this.tz)

    if (this.$store.state.Time.timeObj.eyesHealthReport.ENTER != 0) {
      this.jsTime('eyesHealthReport')
    }
  }
}
</script>

<style scoped lang="scss">
   .Iframe {
     width: 100vw;
     height: 86vh;
     overflow: auto;
     -ms-overflow-style: none;
   }
   .Iframe::-webkit-scrollbar { width: 0 !important }
   .znr {
     width: 100%;
     height: 100%;
   }
   .fh {
     position: fixed;
     bottom: 100px;
     left: 0;
   }
   .dy {top: 38vh;left: 0; margin: 0;}
</style>
